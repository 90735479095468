/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'ngx-toastr/toastr';

@font-face {
  font-family: 'MetaPro';
  font-weight: normal;
  font-display: swap;
  src: url(fonts/MetaPro.woff2) format('woff2');
}

@font-face {
  font-family: 'MetaPro';
  font-weight: bold;
  font-display: swap;
  src: url(fonts/MetaPro-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'MetaPro';
  font-weight: 200;
  font-display: swap;
  src: url(fonts/MetaPro-Thin.woff2) format('woff2');
}

.writing-mode-tb {
  writing-mode: tb;
}

.mat-icon.mat-icon-custom-size {
  width: 100% !important;
  height: 100% !important;
}

.modal-open {
  @apply overflow-hidden;
}

body {
  font-family: 'MetaPro';
  font-variant-numeric: lining-nums;
}

.dlv-toast {
  @apply bg-white rounded-md mt-2 shadow-md;
  padding: 15px 20px;
}

.toast-success {
  background-position: 10px center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj48cGF0aCBmaWxsPSIjMDA5Njg4IiBkPSJNMTYsMy41IEMyMi45MDM1NTk0LDMuNSAyOC41LDkuMDk2NDQwNjMgMjguNSwxNiBDMjguNSwyMi45MDM1NTk0IDIyLjkwMzU1OTQsMjguNSAxNiwyOC41IEM5LjA5NjQ0MDYzLDI4LjUgMy41LDIyLjkwMzU1OTQgMy41LDE2IEMzLjUsOS4wOTY0NDA2MyA5LjA5NjQ0MDYzLDMuNSAxNiwzLjUgWiBNMTYsNC44IEM5LjgxNDQxMDgsNC44IDQuOCw5LjgxNDQxMDggNC44LDE2IEM0LjgsMjIuMTg1NTg5MiA5LjgxNDQxMDgsMjcuMiAxNiwyNy4yIEMyMi4xODU1ODkyLDI3LjIgMjcuMiwyMi4xODU1ODkyIDI3LjIsMTYgQzI3LjIsOS44MTQ0MTA4IDIyLjE4NTU4OTIsNC44IDE2LDQuOCBaIE0yMi4wMjYwMjk0LDExLjU2NDM3MzUgQzIyLjI3OTg3MDEsMTEuODE4MjE0MyAyMi4yNzk4NzAxLDEyLjIyOTc3MTUgMjIuMDI2MDI5NCwxMi40ODM2MTIzIEwxNC4wNzQwMTUxLDIwLjQzNTYyNjUgQzEzLjk3MjQ3ODgsMjAuNTM3MTYyOCAxMy44NDU3MDc5LDIwLjU5ODA4NDYgMTMuNzEzODksMjAuNjE4MzkxOSBMMTMuNjE0Mzk1NywyMC42MjYwMDcxIEMxMy40NDgwNDYyLDIwLjYyNjAwNzEgMTMuMjgxNjk2NywyMC41NjI1NDY5IDEzLjE1NDc3NjMsMjAuNDM1NjI2NSBMOS45NzM5NzA2NSwxNy4yNTQ4MjA4IEM5LjcyMDEyOTg2LDE3LjAwMDk4IDkuNzIwMTI5ODYsMTYuNTg5NDIyOCA5Ljk3Mzk3MDY1LDE2LjMzNTU4MiBDMTAuMjI3ODExNCwxNi4wODE3NDEyIDEwLjYzOTM2ODcsMTYuMDgxNzQxMiAxMC44OTMyMDk1LDE2LjMzNTU4MiBMMTMuNjE0LDE5LjA1NiBMMjEuMTA2NzkwNSwxMS41NjQzNzM1IEMyMS4zNjA2MzEzLDExLjMxMDUzMjcgMjEuNzcyMTg4NiwxMS4zMTA1MzI3IDIyLjAyNjAyOTQsMTEuNTY0MzczNSBaIi8+PC9zdmc+Cg==");
}

.toast-success .toast-message {
  @apply pl-8;
}

.toast-container {
  @apply top-1.5 left-3;
  @screen sm {
    @apply left-auto top-5;
  } 
}
